import React, { useContext, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { Container, Row, Col, Image } from "react-bootstrap";
import GlobalContext from "../../context/GlobalContext";
import { device } from "../../utils";
import { Title, Section, Box, Text, Button } from "../../components/Core";
import API from "../../api/api";
import APINO from "../../api/api-no";
import AsyncSelect from "react-select/async";
import loader from "../../assets/image/loader.svg";
import netomniaCircleLogo from "../../assets/image/png/netomnia-circle-logo.png"
import { graphql, useStaticQuery } from "gatsby";
import { getProperty } from "./../../utils/helperFn";
import jwt from 'jsonwebtoken';
import { navigate } from "gatsby";

const Loader = styled.img`
    @keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
    }

  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
`

const CheckAvailability = (props) => {
    const [postCodeAddress, setPostCodeAddress] = useState({ label: '', value: '' })
    const [searchResult, setSearchResult] = useState({ available: false, salesStatus: null, loading: false })
    const [APIErrorMsg, setAPIErrorMsg] = useState({ title: '', message: '' })
    const gContext = useContext(GlobalContext);
    const inputEl = useRef(null);

    const availableStatuses = [
        'ORDER',
        'PRE_ORDER',
        'RI_ONHOLD_EX_INBUILD',
        'RI_ONHOLD',
        'RI_LANDLORD',
        'RI_INPLANNING_EX_INBUILD',
        'RI_INPLANNING',
        'RI_PHASE3',
        'RI_GENERIC'
    ]

    const data = useStaticQuery(graphql`
        query strapiCheckAvailabilityQuery {
            strapiCheckAvailabilitySection {
                title_order
                title_pre_order
                title_register_interest
                title_register_interest_plus
                title_default
                title_not_found
                title_initial
                text_order
                text_pre_order
                text_register_interest
                text_register_interest_plus
                text_not_found
                text_initial
                text_default
            }
        }
    `);

    const pageData = getProperty(data, 'strapiCheckAvailabilitySection');

    /* If Postcode address is selected -> hit the API endpoint. */
    useEffect(() => {
        if (props.focusInput) {
            inputEl.current.focus();
        }
        if (postCodeAddress && postCodeAddress.value) {

            console.log('%cPostcode Address', 'color:royalblue', postCodeAddress)
            setSearchResult({
                ...searchResult,
                loading: true
            })
            console.log('%c Fetching Premise information...', 'color:RoyalBlue')
            /* 1. Get the Premise for selected Address */
            APINO.get(`/CrmModule/v2.0/premises/${JSON.parse(postCodeAddress.value).SPRN}`)
                .then(response => {
                    let decoded = null
                    let searchPremResults = null
                    if (response.data.data._sessionId) {
                        const token = response.data.data._sessionId;
                        try {
                            decoded = jwt.verify(token, process.env.GATSBY_JWT_TOKEN);
                            console.log('decoded: ', decoded)
                        } catch (err) {
                            decoded = {
                                IsBDUK: "false",
                                NetomniaSalesStatus: 'RI_GENERIC',
                                SalesStatus: null,
                                BuildStatus: null
                            };
                        }
                    }

                    if (Object.keys(response.data.data.properties).length) {

                        response.data.data.properties.NetomniaSalesStatus = decoded.NetomniaSalesStatus;
                        response.data.data.properties.SalesStatus = decoded.SalesStatus;
                        response.data.data.properties.IsBDUK = decoded.IsBDUK;
                        response.data.data.properties.BuildStatus = decoded.BuildStatus;

                        if (!availableStatuses.includes(response.data.data.properties.NetomniaSalesStatus)) {
                            response.data.data.properties.NetomniaSalesStatus = 'RI_GENERIC'
                        }
                        gContext.goSetRegistrationData({
                            ...gContext.goGetRegistrationData(),
                            SearchPremiseResults: response.data.data,
                            bduk: response.data.data.properties.IsBDUK
                        })
                        searchPremResults = response.data.data
                    } else {
                        response.data.data.properties.NetomniaSalesStatus = decoded.NetomniaSalesStatus;
                        response.data.data.properties.SalesStatus = decoded.SalesStatus;
                        response.data.data.properties.IsBDUK = decoded.IsBDUK;
                        response.data.data.properties.BuildStatus = decoded.BuildStatus;

                        postCodeAddress.details.NetomniaSalesStatus = 'RI_GENERIC'
                        const searchPremiseRes = {
                            properties: postCodeAddress.details,
                            salesStatus: null,
                            title: postCodeAddress.label
                        };
                        gContext.goSetRegistrationData({
                            ...gContext.goGetRegistrationData(),
                            SearchPremiseResults: searchPremiseRes,
                            bduk: "false"
                        })
                        searchPremResults = searchPremiseRes
                    }

                    console.log('%c 1. Fetch address premises success', 'color:limegreen', response.data.data.properties.NetomniaSalesStatus)

                    setTimeout(() => {
                        if (Object.keys(response.data.data.properties).length) {
                            setSearchResult({
                                loading: false,
                                salesStatus: response.data.data.properties.NetomniaSalesStatus,
                                available: true
                            })
                        } else {
                            setSearchResult({
                                loading: false,
                                salesStatus: 'RI_GENERIC',
                                available: true
                            })
                        }

                        if (searchPremResults) {
                            if (searchPremResults.properties.NetomniaSalesStatus === 'RI_GENERIC') {
                                navigate("/success-generic/", {
                                    state: {
                                        pageData: {
                                            sprn: searchPremResults?.properties['SPRN'],
                                            directRedirectToYf: false,
                                            riGeneric: true
                                        }
                                    }
                                })
                            } else {
                                if (searchPremResults.properties.SalesStatus === 'ORDER' && searchPremResults.properties.IsBDUK === "false") {
                                    navigate("/success-generic/", {
                                        state: {
                                            pageData: {
                                                sprn: searchPremResults?.properties['SPRN'],
                                                directRedirectToYf: true
                                            }
                                        }
                                    })
                                } else {
                                    if (searchPremResults.properties.IsBDUK === "true") {
                                        navigate(
                                            '/register-bduk/',
                                            {
                                                state: {
                                                    registrationData: gContext.goGetRegistrationData()
                                                }
                                            }
                                        )
                                    } else {
                                        navigate(
                                            '/register/',
                                            {
                                                state: {
                                                    registrationData: gContext.goGetRegistrationData()
                                                }
                                            }
                                        )
                                    }
    
                                }
                            }
                        }
                    }, 1000);
                })
                .catch(error => {
                    const searchPremiseRes = {
                        properties: postCodeAddress.details,
                        salesStatus: null,
                        title: postCodeAddress.label
                    };
                    gContext.goSetRegistrationData({
                        ...gContext.goGetRegistrationData(),
                        SearchPremiseResults: searchPremiseRes,
                        bduk: "true"
                    })
                    setTimeout(() => {
                        setSearchResult({
                            loading: false,
                            salesStatus: null,
                            available: true
                        })
                    }, 1000);
                    console.log('%c API Error while Getting Premise information', 'color:red')
                })
        }
    }, [postCodeAddress])

    const newClassStyles = {
        flex: '0 0 100%',
        maxWidth: '90%'
    };

    /* Get Postcode Data */
    const loadOptionsAsync = (input) => {

        const query = `/CrmModule/v1.0/db/Premise2/search?terms=${input}&fields=properties.PostalCode,properties.PostalCodeNoSpace&schemas=${process.env.GATSBY_PREMISE_SCHEMA}&page=0&size=100&sort=[{%22properties.DoorNumber%22:{%22order%22:%22asc%22}},{%22properties.SPRN%22:{%22order%22:%22asc%22}}]`

        return API.get(query)
            .then(function (response) {
                return response.data.data.map(category => ({
                    value: JSON.stringify({
                        UDPRN: category.properties.UDPRN,
                        UMPRN: category.properties.UMPRN,
                        SPRN: category.properties.SPRN
                    }),
                    label: category.properties.FullAddress,
                    details: category.properties
                }));
            })
            .catch(function (error) {
                console.log(error);
                setPostCodeAddress('')
                setAPIErrorMsg({
                    title: 'Postcode Search Error',
                    message: 'There was a problem accessing our servers. Please try again later.'
                })
                alert('There was an error accessing our servers.');
            });
    }

    const renderSearchResult = () => {
        if (searchResult.loading) {
            return (
                <Col sm={12} className="p-2 mt-3 text-center">
                    <Loader alt="" src={loader} />
                </Col>
            )
        } else {
            return (
                <Col sm={12} md={props.customTitle !== "" || props.customSubtitle !== "" ? 6 : 12} className="mx-auto pt-4" style={props.isBig ? newClassStyles : null}>
                    <form action="/" ref={gContext.postcodeRef}>
                        <div className="position-relative">
                            <AsyncSelect
                                ref={inputEl}
                                id="reactSelect"
                                isClearable
                                autoFocus={props.isFocused}
                                isSearchable={true}
                                placeholder="Your postcode"
                                className="reactSelect text-left check-availability-select"
                                loadOptions={loadOptionsAsync}
                                styles={{
                                    option: (provided, state) => ({
                                        ...provided,
                                        fontSize: '1.2em',
                                        ':hover': {
                                            backgroundColor: '#f8f8f8'
                                        }
                                    }),
                                    control: (provided) => {
                                        return {
                                            ...provided,
                                            borderRadius: 10,
                                            padding: "1rem 1rem",
                                            outline: "none",
                                            boxShadow: "none",
                                            fontSize: '1.2em'
                                        };
                                    }
                                }}
                                onChange={option => {
                                    setPostCodeAddress(option)
                                }}
                            />
                        </div>
                    </form>
                </Col>
            )
        }
    }

    const renderSectionTitle = () => {

        if (searchResult.available && !searchResult.loading && searchResult.salesStatus) {

            switch (searchResult.salesStatus) {

                case 'ORDER':
                    return `${pageData.title_order}`
                case 'PRE_ORDER':
                    return `${pageData.title_pre_order}`
                case 'REGISTER_INTEREST_PLUS':
                    return `${pageData.title_register_interest_plus}`
                case 'REGISTER_INTEREST':
                    return `${pageData.title_register_interest}`
                default:
                    return `${pageData.title_default}`
            }
        } else {
            return <span>{pageData.title_not_found}</span>
        }
    }

    const renderSectionSubtitle = () => {
        if (searchResult.available && !searchResult.loading && searchResult.salesStatus) {

            switch (searchResult.salesStatus) {

                case 'ORDER':
                    return <span>{pageData.text_order}</span>
                case 'PRE_ORDER':
                    return <span>{pageData.text_pre_order}</span>
                case 'REGISTER_INTEREST_PLUS':
                    return <span>{pageData.text_register_interest_plus}</span>
                case 'REGISTER_INTEREST':
                    return <span>{pageData.text_register_interest}</span>
                default:
                    return <span>{pageData.text_default}</span>
            }

        } else {
            return <span>{pageData.text_not_found}</span>
        }
    }

    return (
        <div ref={gContext.checkAvailabilitySection} style={{ width: '100%', display: 'block', margin: "auto" }} className={props.className}>
            {
                props.customTitle == "" &&
                props.customSubtitle == "" &&
                <div className="single-check-availability">
                    {renderSearchResult()}
                </div>
            }

            {
                (((!props.customTitle && props.customTitle) !== "" || props.customTitle) ||
                    ((!props.customSubtitle && props.customSubtitle !== "") || props.customSubtitle)) &&
                <Section className="position-relative pt-5">
                    <Container>
                        <Row className="justify-content-center">
                            {
                                !props.hideLogo &&
                                <Col sm={12} className="text-center check-availability-logo">
                                    <img alt="" src={netomniaCircleLogo} />
                                </Col>
                            }
                            <Col lg="12" className="text-center pb-3">
                                <div className="pt-5 reduce-padding">
                                    {
                                        props.customTitle !== "" &&
                                        <Title className="middleTittle pre-line">
                                            {props.customTitle ? <div dangerouslySetInnerHTML={{ __html: props.customTitle }}></div> : renderSectionTitle()}
                                        </Title>
                                    }
                                    {
                                        props.customSubtitle !== "" &&
                                        <Text className="mt-3 mb-3 pre-line">
                                            {props.customSubtitle ? <div dangerouslySetInnerHTML={{ __html: props.customSubtitle }}></div> : renderSectionSubtitle()}
                                        </Text>
                                    }
                                </div>
                            </Col>
                        </Row>
                        <Row className="mx-auto">
                            {renderSearchResult()}
                        </Row>
                    </Container>
                </Section>
            }
        </div>
    )
}

export default CheckAvailability;
